import { FC } from 'react';
import { Brand } from '~/application/components/Brand/Brand';
import { Container } from '~/application/components/Container/Container';
import { useHeader, Props } from './useHeader';
import styles from './Header.module.css';
import { LoginUser } from '~/domain/auth/components/LoginUser/LoginUser';

export const Header: FC<Props> = (props) => {
  const { loginUser, isClientReady } = useHeader(props);

  return (
    <header>
      <Container>
        <div className={styles.inner}>
          <Brand />
          {isClientReady &&
          <>
              {!loginUser &&
            <nav>
                  <ul className={styles.navList}>
                    <li>
                      <a href="/signin">ログイン</a>
                    </li>
                    <li>
                      <a href="/signup">アカウント作成</a>
                    </li>
                  </ul>
                </nav>}

              {loginUser && <LoginUser user={loginUser} />}
            </>}

        </div>
      </Container>
    </header>);

};