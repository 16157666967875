import { useEffect, useState } from 'react'
import { LoginUserDTO } from '~/domain/user/dto/LoginUserDTO'

export interface Props {
  loginUser: LoginUserDTO | null
}

export const useHeader = (props: Props) => {
  const [isClientReady, setIsClientReady] = useState(false)
  const { loginUser } = props

  useEffect(() => {
    setIsClientReady(true)
  }, [])

  return {
    loginUser,
    isClientReady,
  }
}
